import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { CircularProgress } from "@mui/material";

const currentIdToken = async () => {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
};

async function getDashboardUrl(idToken: string) {
  return await fetch(
    `${process.env.REACT_APP_RESTURL}/quicksight?${new URLSearchParams({
      sessionLifetime: "15",
    }).toString()}`,
    {
      headers: {
        Authorization: idToken || "",
      },
      method: "GET",
    }
  );
}

export default function Dashboard() {
  const [dashboardUrl, setDashboardUrl] = useState<string>();
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const fetchDashboardData = async () => {
    try {
      setLoadingData(true);
      setDashboardUrl(undefined);
      const response = await getDashboardUrl(await currentIdToken()).then(
        (response) => response.json()
      );
      const { dashboardUrl } = response;

      // cookies.set(dashboardId, dashboardUrl, {
      //   expires: new Date(sessionExpiration)
      // });
      setDashboardUrl(dashboardUrl);
    } catch (err) {
      console.error(err);
    }
    setLoadingData(false);
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  return (
    <React.Fragment>
      {loadingData || !dashboardUrl ? (
        <CircularProgress size={60} />
      ) : (
        <Iframe
          url={dashboardUrl}
          height="100%"
          width="100%"
          frameBorder={0}
        ></Iframe>
      )}
    </React.Fragment>
  );
}
